import React, {useEffect, useState} from 'react';
import {isEmpty} from 'lodash';
import {Helmet} from 'react-helmet-async';
import {useDispatch, useSelector} from 'react-redux';
import update from 'immutability-helper';
import {AnyAction} from 'redux';

import cookies from 'web/helpers/cookies';
import sanitizeReturnPath from 'web/helpers/routing/sanitize_return_path';
import ActiveCreditCard from 'web/components/active_credit_card';
import MarketLayout from 'web/components/market_layout';
import Panel from 'web/components/panel';
import CreditCardForm from 'web/components/stripe_credit_card_form';
import {
  reducer as creditCardFormReducer,
  actions as creditCardFormActions,
} from 'web/components/stripe_credit_card_form/duck';
import {AppDispatch, PageType} from 'web/helpers/redux_client';
import StripeContext from 'web/components/stripe_credit_card_form/stripe_context';

import AccountLayout from '../components/layout';
import {StoreData} from './controller';

const onCreditCardSave = (): void => {
  const returnTo = cookies.getCookie('returnTo');
  window.location.href = sanitizeReturnPath({
    path: returnTo,
    defaultPath: '/account?confirmChanges=true',
  });
};

const CreditCardPage: PageType<StoreData | undefined, AnyAction> = () => {
  const [setupIntentLoading, setSetupIntentLoading] = useState(true);

  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((s: StoreData) => ({
    user: s.user,
    creditCardForm: s.creditCardForm,
    showCaptcha: s.showCaptcha,
    recaptchaSitekey: s.recaptchaSitekey,
  }));

  useEffect(() => {
    dispatch(
      creditCardFormActions.createSetupIntent(() => {
        setSetupIntentLoading(false);
      }),
    );
  }, [dispatch]);

  return (
    <MarketLayout disableUpcomingOrdersBanner disableBasketDropdown>
      <Helmet>
        <title>Update Payment | Good Eggs</title>
        <script src="https://js.stripe.com/v3/" />
        <script src="https://www.google.com/recaptcha/api.js" />
      </Helmet>

      <div className="credit-card-page">
        <AccountLayout slug="creditcard">
          <Panel header="Payment">
            <div className="credit-card-page__section">
              <div className="credit-card-page__card-details">
                {state.user?.active_card == null || isEmpty(state.user?.active_card) ? (
                  <div>You don{"'"}t have a payment method yet.</div>
                ) : (
                  <ActiveCreditCard
                    activeCard={state.user.active_card}
                    prefix="Current Payment: "
                  />
                )}
              </div>
              <StripeContext>
                <CreditCardForm
                  captchaToken={state.creditCardForm?.captchaToken}
                  creditCardError={state.creditCardForm?.creditCardError}
                  onCreditCardSave={onCreditCardSave}
                  showCaptcha={state.showCaptcha}
                  recaptchaSitekey={state.recaptchaSitekey}
                  setupIntentId={state.creditCardForm?.setupIntentId}
                  isSetupIntentLoading={setupIntentLoading}
                />
              </StripeContext>
            </div>
          </Panel>
        </AccountLayout>
      </div>
    </MarketLayout>
  );
};

CreditCardPage.pageName = 'Update Payment';

/*
This is the only reducer so the state from it will be merged with global data that is loaded during the preloadStore
step of createStore in redux_layout.js
 */
CreditCardPage.reducer = (state, action) =>
  update(state, {
    creditCardForm: {$set: creditCardFormReducer(state?.creditCardForm, action)},
  });

export default CreditCardPage;
